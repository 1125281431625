import * as React from 'react'
import { between } from 'polished'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

import { Container } from 'components/common/styles'

import TerminalSVG from 'assets/images/rewards/terminal.svg'
import CardSVG from 'assets/images/comcard.svg'


type Props = {};

const AdaptiveContainer = styled(Container)`
  @media screen and (max-width: 768px) {
    padding: 0;
  }
`

const SectionContainer = styled.div`
  border-radius: 60px;
  background: #D4EBE8;
  
  display: flex;
  flex-direction: row;
  width: 100%;
  
  padding-right: 5%;
  margin-bottom: 11%;
  
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding-right: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-bottom: calc((100vw - 40px) / 7);
  }
`

const Meta = styled.div`
  padding: 20px 100px 20px 115px;
  max-width: 60%;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @media screen and (max-width: 1300px) {
    padding: 20px ${between('40px', '100px', '768px', '1300px')} 20px ${between('35px', '100px', '768px', '1300px')};
  }
  
  @media screen and (max-width: 768px) {
    max-width: 100%;
    padding: 40px;
  }
`
const Title = styled.h1`
  font-weight: bold;
  font-size: ${between('34px', '40px', '768px', '1300px')};
  line-height: 1.5;
  color: #1D1E21;
  
  @media screen and (min-width: 1300px) {
    font-size: 40px;
  }
`
const SubTitle = styled.p`
  margin-top: 32px;
  font-size: 16px;
  line-height: 1.5;
  color: #666;
  
  @media screen and (max-width: 1300px) {
    font-size: 14px;
  }
  
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`

const rotation = keyframes`
   from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const OuterCircle = styled.div`
  position: relative;
  border-radius: 50%;
  width: 4.77em;
  height: 4.77em;
  margin-top: 60px;
  overflow: visible;
  
  @media screen and (max-width: 768px) {
    margin-top: 0;
  }
  
  > svg {
    width: 100%;
    height: 100%;
    
    .outer {
      animation: ${rotation} linear 60s infinite;
      transform-origin: center center;
    }
  }
`

const TerminalAnimation = keyframes`
  0% {
    transform: translateY(-60%) translateX(-50%);
  }
  100% {
    transform: translateY(-50%) translateX(-50%);
  }
`

const Terminal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-60%) translateX(-50%);
  
  width: 3.28em;
  height: 3.05em;
  
  animation: ${TerminalAnimation} ease-in 1s forwards;
  animation-delay: 1000ms;
`

const KeyPad = styled.img`
  position: relative;
  z-index: 5;
  
  width: 100%;
`

const CardAnimation = keyframes`
  0% {
    top: 2.67em;
  }
  100% {
    top: 1.27em;
  }
`

const Card = styled.img`
  border-radius: 16px;
  box-shadow: 0 .04em .09em rgba(0, 0, 0, 0.11);
  position: absolute;
  z-index: 4;
  width: 2.34em;
  
  left: 50%;
  transform: translateX(-50%);
  top: 2.67em;
  
  animation: ${CardAnimation} ease-in 1s forwards;
  animation-delay: 1000ms;
`

const TerminalBase = styled.div`
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  border-radius: .24em;
  top: .10em;
  background: #E5E5E5;
`

const ImageContainer = styled.div`
  width: 44%;
  
  font-size: 100px; 
  display: flex;
  flex-direction: column; 
  align-items: center;
  
  @media screen and (max-width: 1280px) {
    font-size: 70px;
  }
  
  @media screen and (max-width: 1000px) {
    font-size: 60px;
  }
  
  @media screen and (max-width: 768px) {
    align-self: center;
    // width: calc(100% - 40px);
    width: 100%;
    font-size: calc((100vw - 40px) / 7);
    
    
  }
`

const Transactions = styled.div`display: none;`

const AbstractTransaction = styled.div`
  position: absolute;
`

const DotAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: .5;
    }
  70% {
    transform: scale(2.45);
    opacity: 0;
  }
  100% {
    transform: scale(2.45);
    opacity: 0;
  }
`
const Dot = styled.div`
  position: relative;
  width: .11em;
  height: .11em;
  background-color: ${props => props.theme.colors.green100};
  border-radius: 50%;
  
  &:after {
    display: block;
    content: "";
    width: .11em;
    height: .11em;
    background-color: ${props => props.theme.colors.green100};
    border-radius: 50%;
    opacity: .1;
    
    transform: scale(2.45);
    animation: ${DotAnimation} ease-in-out 2s infinite;
  }
`
const Info = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;
`
const Sum = styled.div`
  font-size: .24em;
  line-height: 1.14;
  font-weight: 600;
  color: ${props => props.theme.colors.green100};
`
const Category = styled.div`
  font-size: .114em;
  line-height: 1.6;
  font-weight: 600;
  color: ${props => props.theme.colors.green100};
`

const TransactionOne = styled(AbstractTransaction)`
  top: 1.26em;
  left: .21em;
  
  ${Info} {
    right: .19em;
    top: -.09em;
  }
`
const TransactionTwo = styled(AbstractTransaction)`
  top: .26em;
  left: 1.17em;
  
  ${Info} {
    right: .11em;
    top: 0;
    transform: translateY(-100%);
  }
`
const TransactionThree = styled(AbstractTransaction)`
  top: .46em;
  left: 3.8em;
  
  ${Info} {
    left: .25em;
    top: -.25em;
  }
`
const TransactionFour = styled(AbstractTransaction)`
  top: 1.75em;
  left: 4.64em;
  
  ${Info} {
    left: .18em;
    top: -.09em;
  }
`

const Hero: React.FC<Props> = props => {
  return (
    <AdaptiveContainer>
      <SectionContainer>
        <Meta>
          <Title>Rewards that work for you</Title>
          <SubTitle>
            Earning rewards with ComCard is easy, and every ComCard customer qualifies. Start spending and start
            earning.
          </SubTitle>
        </Meta>
        <ImageContainer>
          <OuterCircle>
            <svg viewBox="0 0 477 477" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="241.5" cy="237.5" r="181.5" fill="#B9E4E0" />
              <circle className="outer" cx="238.5" cy="238.5" r="238" stroke="#77C8C2" strokeLinejoin="round"
                      strokeDasharray="8 8" />
            </svg>
            <Terminal>
              <KeyPad src={TerminalSVG} />
              <Card src={CardSVG} />
              <TerminalBase />
            </Terminal>
            <Transactions>
              <TransactionOne>
                <Dot />
                <Info>
                  <Sum>+8.14$</Sum>
                  <Category>ABC Hardware</Category>
                </Info>
              </TransactionOne>
              <TransactionTwo>
                <Dot />
                <Info>
                  <Sum>+20.83$</Sum>
                  <Category>Cool Furniture</Category>
                </Info>
              </TransactionTwo>
              <TransactionThree>
                <Dot />
                <Info>
                  <Sum>+13.14$</Sum>
                  <Category>World of Concrete</Category>
                </Info>
              </TransactionThree>
              <TransactionFour>
                <Dot />
                <Info>
                  <Sum>+40.37$</Sum>
                  <Category>Wood Market</Category>
                </Info>
              </TransactionFour>
            </Transactions>
          </OuterCircle>
        </ImageContainer>
      </SectionContainer>
    </AdaptiveContainer>
  )
}

export default Hero
