import * as React from 'react'
import { BlockTitle, Container } from 'components/common/styles'
import FeaturesList from 'components/features-list'
import styled from '@emotion/styled'

import HandSvg from 'assets/images/icons/hand_button.svg'
import CardSvg from 'assets/images/icons/credit_card.svg'
import CashSvg from 'assets/images/icons/coins.svg'

type Props = {};

const AdaptiveContainer = styled(Container)`
  @media screen and (max-width: 768px) {
    padding: 0 17px;
  }
`

const Title = styled(BlockTitle)`
  margin-bottom: 45px;
`

const Features: React.FC<Props> = props => {
  return (
    <AdaptiveContainer>
      <FeaturesList items={[
        {
          icon: (<img src={HandSvg} />),
          title: 'Redemption you control',
          description: 'Use rewards and cashback as you wish, with flexible redemption that won’t devalue cash.',
        },
        {
          icon: (<img src={CardSvg} />),
          title: 'No limits',
          description: 'Receive unlimited cashback and other rewards through our growing merchant network.',
        },
        {
          icon: (<img src={CashSvg} />),
          title: 'Additional cash',
          description: 'Receive up to 30% additional cashback by paying off balances early.',
        },
      ]} />
    </AdaptiveContainer>
  )
}

export default Features
