import React from 'react'
import Layout from 'layouts/default'
import Hero from 'components/pages/rewards/hero'
import Features from 'components/pages/rewards/features'
import Comparison from 'components/pages/rewards/comparison'
import Cashback from 'components/pages/rewards/receives'
import QualifySection from 'components/qualify'
import ContactBlock, { Visibility } from 'components/contact-block'
import styled from '@emotion/styled'

const CashbackContainer = styled.div`
  margin-top: 120px;
  align-self: stretch;
  @media screen and (max-width: 768px) {
    margin-top: 60px;
  }
`

const RewardsPage: React.FC = props => {
  React.useEffect(() => {
    window.analytics.page('Rewards')
  }, [])
  return (
    <Layout title="Rewards | ComCard">

      <Hero />
      <Features />
      <CashbackContainer> <Cashback /> </CashbackContainer>
      <Comparison />
      {/*<Perks />*/}

      <QualifySection />
      <ContactBlock visibility={Visibility.CUSTOMER} />
    </Layout>
  )
}

export default RewardsPage
