import * as React from 'react'
import { BlockTitle, Container } from '../common/styles'
import styled from '@emotion/styled'

const ColoredBlock = styled.div`
  background: #e4f1ef;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 90px 0;
  @media screen and (max-width: 768px) {
    padding: 60px 0;
  }
`

const Title = styled(BlockTitle)`
  line-height: 1.5;
  @media screen and (min-width: 1024px) {
    width: 80%;
  }
`

const ElementTitle = styled.h3`
  font-size: 22px;
  line-height: 1.5;
  font-weight: 600;
  color: #000;
`

const ContactBlockContainer = styled.div`
  display: grid;
  grid-gap: 24px;

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const ContactElements = styled.div`
  display: grid;
  grid-gap: 40px;

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`
const ContactElement = styled.div`
  max-width: 220px;
  display: grid;
  grid-gap: 10px;
`

const ContactPhone = styled.a`
  font-size: 14px;
  line-height: 1.5;
  color: #000;
  text-decoration: none;
`

const ContactEmail = styled(ContactPhone)`
  color: #666668;
`

export enum Visibility {
  CUSTOMER,
  MERCHANT,
  BOTH
}

type Props = {
  visibility?: Visibility;
};

const ContactBlock: React.FC<Props> = ({ visibility = Visibility.BOTH }) => {
  return (
    <ColoredBlock>
      <Container>
        <ContactBlockContainer>
          <Title>
            Got questions? <br />
            We're here to help.
          </Title>

          <ContactElements>
            {(visibility == Visibility.BOTH || visibility == Visibility.CUSTOMER) && (
              <ContactElement>
                <ElementTitle>Customer Support</ElementTitle>
                <ContactPhone href="tel:+18449030903">(844) 903 - 0903</ContactPhone>
                <ContactEmail href="mailto:support@comcard.us">support@comcard.us</ContactEmail>
              </ContactElement>
            )}
            {(visibility == Visibility.BOTH || visibility == Visibility.MERCHANT) && (
              <ContactElement>
                <ElementTitle>Merchants Help</ElementTitle>
                <ContactPhone href="tel:+18449030903">(844) 903 - 0903</ContactPhone>
                <ContactEmail href="mailto:merchant@comcard.us">merchant@comcard.us</ContactEmail>
              </ContactElement>
            )}
          </ContactElements>
        </ContactBlockContainer>
      </Container>
    </ColoredBlock>
  )
}

export default ContactBlock
